// import { db } from '@/firebase'
import _ from 'lodash'
import moment from 'moment'
import { mapState } from 'vuex'

export default {
    data() {
        return {
            shop: null,
            isChecked:false,
            startDate:'',
            endDate:'',
            productFields: [
                {
                    key: 'name',
                    label: '產品名稱',
                    sortable: true,
                },
                {
                    key: 'count',
                    label: '數量',
                    formatter: value => {
                        return value ? `${value}件` : `0件`
                    },
                    sortable: true,
                },
            ]
        }
    },


    async created() {
        this.$store.state.startDate = null
        this.$store.state.endDate = null
    },

    watch: {
        async startDate(newStartDate){
            this.$store.state.startDate = newStartDate
            await this.$store.dispatch('bindOrdersBySelected')
        } ,
        async endDate(newEndDate){
            this.$store.state.endDate = newEndDate
            await this.$store.dispatch('bindOrdersBySelected')
        }        
    },

    computed: { 
    ...mapState({
        products: state => state.products,
        orders: state => state.orders,
        shops: state => state.shops,
    }),

    shopOptions() {
        if (!this.shops) return []
        let ret = this.shops.map(shop => {
            return {
                text: shop.name,
                value: {
                    id: shop.id,
                    name: shop.name,
                },
            }
        })
        ret.unshift({ text: '全部分店', value: null })
        return ret
    },

        productByOrders() {
            if (!this.startDate) return []

            let startDate = Number(moment(this.startDate).format('YYYYMMDD'))
            let endDate = Number(moment(this.endDate ? this.endDate : this.startDate).format('YYYYMMDD'))

            let products = _.cloneDeep(this.products)
            let orders = _.cloneDeep(this.orders)

            _.forEach(products, product => {
                product.count = 0
                product.total = 0
            })
           
            for (let currentDate = startDate; currentDate <= endDate; currentDate++) {
                if (moment(String(currentDate)).format('YYYY年MM月DD日') == 'Invalid date') continue
                if (this.orders.length > 0) {
                    _.forEach(orders, order => {
                        if(this.shop){
                              if (String(order.uniNum).includes(String(currentDate)) && order.status == '2' && order.shop.id == this.shop.id){
                            _.forEach(order.products, product => {
                                let index = _.findIndex(products, { id: product.id })
                                if (index > -1) {
                                        products[index].count += product.count
                                        products[index].total += product.total
                                }
                            })
                        }
                        }
                        else if(String(order.uniNum).includes(String(currentDate)) && order.status == '2') {
                            _.forEach(order.products, product => {
                                let index = _.findIndex(products, { id: product.id })
                                if (index > -1) {
                                        products[index].count += product.count
                                        products[index].total += product.total
                                }
                            })
                        }
                        })
                }
            }
            return _.orderBy(products, ['count'], ['desc'])
        },
    },
}