import _ from 'lodash'
import moment from 'moment'
import { mapState } from 'vuex'

export default {
    data() {
        return {
            shop: null,
            isChecked:false,
            startDate:'',
            endDate:'',
            orderFields: [
                {
                    key: 'date',
                    label: '日期',
                    sortable: true,
                },
                {
                    key: 'cash',
                    label: '現金',
                    formatter: value => {
                        return `$${value}`
                    },
                    sortable: true,
                },
                {
                    key: 'nonCash',
                    label: '非現金',
                    formatter: value => {
                        return `$${value}`
                    },
                    sortable: true,
                },
                {
                    key: 'count',
                    label: '總單數',
                    formatter: value => {
                        return `${value}單`
                    },
                    sortable: true,
                },
                {
                    key: 'total',
                    label: '總計',
                    formatter: value => {
                        return `$${value}`
                    },
                    sortable: true,
                },
            
            ],        
        }
    },

    created() {
        this.$store.state.startDate = null
        this.$store.state.endDate = null
    },

    watch: {
        async startDate(newStartDate){
            this.$store.state.startDate = newStartDate
            await this.$store.dispatch('bindOrdersBySelected')
        } ,
        async endDate(newEndDate){
            this.$store.state.endDate = newEndDate
            await this.$store.dispatch('bindOrdersBySelected')
        }        
    },

    computed: {
        ...mapState({
            orders: state => state.orders,
            shops: state => state.shops,
        }),
        
        shopOptions() {
            if (!this.shops) return []
            let ret = this.shops.map(shop => {
                return {
                    text: shop.name,
                    value: {
                        id: shop.id,
                        name: shop.name,
                    },
                }
            })
            ret.unshift({ text: '全部分店', value: null })
            return ret
        },

        orderSummary() {
            let res = {
                dateCount: 0,
                cash: 0,
                cashCount: 0,
                nonCash: 0,
                count: 0,
                total: 0,
                days: [],
            }
            if (!this.startDate) return res

            let startDate = Number(moment(this.startDate).format('YYYYMMDD'))
            let endDate = Number(moment(this.endDate ? this.endDate : this.startDate).format('YYYYMMDD'))

            for (let currentDate = startDate; currentDate <= endDate; currentDate++) {
                if (moment(String(currentDate)).format('YYYY年MM月DD日') == 'Invalid date') continue
                let day = {
                    date: moment(String(currentDate)).format('YYYY年MM月DD日'),
                    count: 0,
                    total: 0,
                    cash: 0,
                    nonCash: 0,
                }

                if (this.shop) {
                    _.cloneDeep(this.orders).forEach(order => {
                        if (order.status != '2') return
                        if (String(order.uniNum).includes(String(currentDate)) && order.shop.id == this.shop.id) {
                            day.cash  += order.payment.method == 'cash' ? order.payment.paid : 0
                            day.nonCash  += order.payment.method != 'cash' ? order.payment.paid : 0
                            day.count += 1
                            day.total += order.grandTotal
                        }
                    })
                } else {
                    _.cloneDeep(this.orders).forEach(order => {
                        if (order.status != '2') return
                        if (String(order.uniNum).includes(String(currentDate))) {
                            day.cash  += order.payment.method == 'cash' ? order.payment.paid : 0
                            day.nonCash  += order.payment.method != 'cash' ? order.payment.paid : 0
                            day.count += 1
                            day.total += order.grandTotal
                        }
                    })
                }

                res.dateCount += 1
                res.cash += day.cash
                res.nonCash += day.nonCash
                res.count += day.count
                res.total += day.total
                res.days.push(day)
            }

            return res
        },

   
    },
 
}