import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home/index'
import OrderList from '@/views/OrderList/index'
import OrderDetail from '@/views/OrderDetail/index'
import ShopList from '@/views/ShopList/index'
import ShopDetail from '@/views/ShopDetail/index'
import CategoryList from '@/views/CategoryList/index'
import CategoryDetail from '@/views/CategoryDetail/index'
import ProductList from '@/views/ProductList/index'
import ProductDetail from '@/views/ProductDetail/index'
import ExtraList from '@/views/ExtraList/index'
import ExtraDetail from '@/views/ExtraDetail/index'
import ItemList from '@/views/ItemList/index'
import ItemDetail from '@/views/ItemDetail/index'
import SalesReport from '@/views/SalesReport/index.vue'
import ProductsReport from '@/views/ProductsReport/index.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/home',
        component: Home,
        children: [
			// 銷售訂單頁面
			{
				path: '/orders',
				component: OrderList,
			},
			{
				path: '/orders/:operation',
				component: OrderDetail,
			},
			{
				path: '/orders/:operation/:id',
				component: OrderDetail,
			},
			// 分店頁面
			{
				path: '/shops',
				component: ShopList,
			},
			{
				path: '/shops/:operation',
				component: ShopDetail,
			},
			{
				path: '/shops/:operation/:id',
				component: ShopDetail,
			},
			// 分類頁面
			{
				path: '/categories',
				component: CategoryList,
			},
			{
				path: '/categories/:operation',
				component: CategoryDetail,
			},
			{
				path: '/categories/:operation/:id',
				component: CategoryDetail,
			},
			// 商品頁面
			{
				path: '/products',
				component: ProductList,
			},
			{
				path: '/products/:operation',
				component: ProductDetail,
			},
			{
				path: '/products/:operation/:id',
				component: ProductDetail,
			},
			// 配料頁面
			{
				path: '/extras',
				component: ExtraList,
			},
			{
				path: '/extras/:operation',
				component: ExtraDetail,
			},
			{
				path: '/extras/:operation/:id',
				component: ExtraDetail,
			},
            // 配料選項頁面
			{
				path: '/items',
				component: ItemList,
			},
			{
				path: '/items/:operation',
				component: ItemDetail,
			},
			{
				path: '/items/:operation/:id',
				component: ItemDetail,
			},
			{
			path: '/salesReport',
			name: 'SalesReport',
			component: SalesReport
			},
			{
			path: '/productsReport',
			name: 'ProductsReport',
			component: ProductsReport
			},
        ]
    },
	{
		path: '*',
		redirect: '/home'
	}
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

export default router
