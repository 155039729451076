<template>
    <b-row>
        <b-col>
            <h5>{{ text }}</h5>
            <p>點擊下方項目以編輯{{ text }}</p>
        </b-col>
        <b-col>
            <b-button class="float-right" variant="primary" :to="`/${value}/create`">建立{{ text }}</b-button>
        </b-col>
    </b-row>
</template>

<script>
export default {
    props: ['text', 'value']
}
</script>