import { mapState } from 'vuex'
import { Order } from '@/models/index'
import { renderPrice, renderProductSubTotal } from '@/utils/index'
import _ from 'lodash'

export default {
    data() {
        return {
            operation: this.$route.params.operation,
            extras: [],
            count: 1,
            order: null,
            selectedProduct: null,
            statusOptions: [
                { text: '廢除', value: '3' },
                { text: '完成', value: '2' },
            ],
        }
    },

    async created() {
        switch (this.operation) {
            case 'create':
                this.order = Order()
                break
            default:
                this.order = await this.$store.dispatch('getOrder', this.$route.params.id)
                break
        }
        if (!this.order) return this.$router.push('/orders')
    },

    computed: {
        ...mapState({
            orders: state => state.orders,
            shops: state => state.shops,
            products: state => state.products,
        }),

        computedSubTotal() {
            const items = _.flatten(_.map(_.cloneDeep(this.extras), extra => extra.items))
            let productPrice = 0
            let itemsPrice = 0
            switch (this.order.type) {
                case 'toGo':
                    productPrice = this.selectedProduct.takeawayPrice
                    itemsPrice = _.chain(items).map('takeawayPrice').sum().value()
                    break
                case 'mobileToGo':
                    productPrice = this.selectedProduct.takeawayPrice
                    itemsPrice = _.chain(items).map('takeawayPrice').sum().value()
                    break
                case 'mobileDelivery':
                    productPrice = this.selectedProduct.deliveryPrice
                    itemsPrice = _.chain(items).map('deliveryPrice').sum().value()
                    break
            }
            return (productPrice + itemsPrice) * this.count
        },

        computedGrandTotal() {
            return _.cloneDeep(this.order.products).map(product => {
                switch (this.order.method) {
                    case 'toGo':
                        return (product.takeawayPrice + _.chain(product.items).map('takeawayPrice').sum().value()) * product.count
                    case 'mobileToGo':
                        return (product.takeawayPrice + _.chain(product.items).map('takeawayPrice').sum().value()) * product.count
                    case 'mobileDelivery':
                        return (product.deliveryPrice + _.chain(product.items).map('deliveryPrice').sum().value()) * product.count
                }
            }).reduce((grandTotal, subTotal) => {
                return grandTotal + subTotal
            }, 0)
        },

        computedPaymentChange() {
            return this.order.payment.paid - this.computedGrandTotal
        },

        shopOptions() {
            return _.cloneDeep(this.shops).map(shop => {
                return {
                    text: shop.name,
                    value: {
                        id: shop.id,
                        name: shop.name,
                        address: shop.address,
                        phone: shop.phone,
                    }
                }
            })
        }
    },

    methods: {
        renderPrice,
        renderProductSubTotal,

        renderActiveClass(extraId, itemId) {
            let extra = _.find(this.extras, { id: extraId })
            return extra ? _.find(extra.items, { id: itemId }) : false
        },

        selectProduct(product) {
            this.selectedProduct = product
            this.extras = _.cloneDeep(this.selectedProduct.extras).map(extra => {
                extra.items = _.filter(_.cloneDeep(extra.items), item => item.isDefault === true)
                return extra
            })
        },

        addExtraItem(extraIndex, item) {
            const extra = this.extras[extraIndex]
            const itemIndex = _.indexOf(_.map(extra.items, 'id'), item.id)
            if (itemIndex > -1) return extra.items.splice(itemIndex, 1)
            if (!extra.isMultiple) return extra.items.splice(itemIndex, 1, item)
            if (extra.items.length < extra.maxSelected) return extra.items.push(item)
        },

        addProduct() {
            const product = {
                id: this.selectedProduct.id,
                name: this.selectedProduct.name,
                items: _.cloneDeep(this.extras).flatten().map(extra => extra.items),
                takeawayPrice: this.selectedProduct.takeawayPrice,
                deliveryPrice: this.selectedProduct.deliveryPrice,
                count: this.count,
                remark: '',
            }
            this.order.products.push(product)
            this.order.products = _.cloneDeep(this.order.products).reduce((result, value) => {
                let product = _.find(result, { id: value.id })
                if (product) {
                    _.isEqual(product.items, value.items) ? product.count += value.count :  result.push(value)
                } else {
                    result.push(value)
                }
                return result
            }, [])
            this.selectedProduct = null
            this.count = 1
        },

        removeProduct(index) {
            this.order.products.splice(index, 1)
        },

        async submit() {
            try {
                this.order.grandTotal = this.computedGrandTotal
                // 廢除訂單單
                if( this.order.status === "3" ) {
                    this.order.grandTotal = 0 
                }

                await this.$store.dispatch(this.order.id ? 'updateOrder' : 'createOrder', this.order)
                this.$root.$bvToast.toast(`銷售訂單${this.order.id ? '更新' : '新增'}成功`, { title: '成功', variant: 'success' })
                this.$router.push('/orders')
            } catch (e) {
                console.log(e)
                this.$root.$bvToast.toast(`銷售訂單${this.order.id ? '更新' : '新增'}失敗，請重試`, { title: '失敗', variant: 'danger' })
            }
        },
    },
}
