import ListHeader from '@/components/ListHeader'
import ListTable from '@/components/ListTable'
import { mapState } from 'vuex'
import _ from 'lodash'
import moment from 'moment'

export default {
    components: {
        ListHeader,
        ListTable,
    },

    data() {
        return {
            shop: null,
            isChecked:false,
            startDate:'',
            endDate:'',
            fields: [
                {
                    key: 'uniNum',
                    label: '訂單',
                    sortable: true, 
                    sortDirection: 'desc',
                },
                {
                    key: 'shop.name',
                    label: '分店',
                    sortable: true, 
                },
                {
                    key: 'grandTotal',
                    label: '總計',
                    sortable: true, 
                    formatter: value => {
                        return '$' + value
                    }
                },
                {
                    key: 'actions',
                    label: '功能',
                    sortable: true, 
                    thStyle: { width: '25%' },
                }
            ]
        }
    },

    async created() {
        this.$store.state.startDate = null
        this.$store.state.endDate = null
    },

    watch: {
        async startDate(newStartDate){
            this.$store.state.startDate = newStartDate
            await this.$store.dispatch('bindOrdersBySelected')
        } ,
        async endDate(newEndDate){
            this.$store.state.endDate = newEndDate
            await this.$store.dispatch('bindOrdersBySelected')
        }        
    },

    computed: {
        ...mapState({
            orders: state => state.orders,
            shops: state => state.shops,
        }),

        shopOptions() {
            if (!this.shops) return []
            let ret = this.shops.map(shop => {
                return {
                    text: shop.name,
                    value: {
                        id: shop.id,
                        name: shop.name,
                    },
                }
            })
            ret.unshift({ text: '全部分店', value: null })
            return ret
        },

        productByOrders() {
            if (!this.startDate) return []
    
            let startDate = Number(moment(this.startDate).format('YYYYMMDD'))
            let endDate = Number(moment(this.endDate ? this.endDate : this.startDate).format('YYYYMMDD'))
 
            let selectedOrders = _.cloneDeep(this.orders)   
            
            for (let currentDate = startDate; currentDate <= endDate; currentDate++) {
                if (moment(String(currentDate)).format('YYYY年MM月DD日') == 'Invalid date') continue
                if (this.orders.length > 0) {
                        if(this.shop){
                            return selectedOrders.filter(order =>{
                                return String(order.shop.id).includes(this.shop.id)
                            })  
                        }
                }
            }
            return _.orderBy(selectedOrders)
        },
    }
}