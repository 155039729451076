// NubeTech Model v1.0 (21032201)
export function Item() {
    return {
        name: '',
    }
}

export function Extra() {
    return {
        name: '',
        items: [],
        minSelected: 0,
        maxSelected: 0,
        isMultiple: false,
    }
}

export function Product() {
    return {
        name: '',
        description: '',
        extras: [],
        takeawayPrice: 0,
        deliveryPrice: 0,
        isSoldOut: false,
    }
}

export function Category() {
    return {
        name: '',
        products: [],
    }
}

export function Shop() {
    return {
        name: '',
        address: '',
        phone: '',
        categories: [],
        coverImage: '',
        uniNums: [],
        openingHours: {
            0: [{ start: '', end: '' }],
            1: [{ start: '', end: '' }],
            2: [{ start: '', end: '' }],
            3: [{ start: '', end: '' }],
            4: [{ start: '', end: '' }],
            5: [{ start: '', end: '' }],
            6: [{ start: '', end: '' }],
        },
        readyTime: 15,
        busyTime: 40,
        reservationDays: 1,
        isBusy: false,
        isClosed: false,
    }
}

export function Order() {
    return {
        uniNum: '',
        type: 'toGo',
        store: null,
        customer: null,
        products: [],
        remark: '',
        payment: null,
        status: 2,
        createTime: null,
        reservationTime: null,
        doingTime: null,
        awaitTime: null,
        completeTime: null,
    }
}

export function Customer() {
    return {
        id: '',
        name: '',
        phone: '',
        birthMonth: '',
        gender: '',
        pointBalance: 0,
        accumulatedPoints: 0,
        orderIds: [],
    }
}