import { mapState } from 'vuex'
import { Product } from '@/models/index'
import _ from 'lodash'

export default {
    data() {
        return {
            operation: this.$route.params.operation,
            product: null,
            newImage: null,
        }
    },

    async created() {
        switch (this.operation) {
            case 'create':
                this.product = Product()
                break;
            default:
                this.product = await this.$store.dispatch('getProduct', this.$route.params.id)
                break;
        }
        if (!this.product) return this.$router.push('/products')
    },

    computed: {
        ...mapState({
            extras: state => state.extras,
        })
    },

    methods: {
        addExtra(obj) {
            if (!_.find(this.product.extras, { id: obj.id })) {
                this.product.extras.push(_.cloneDeep(obj))
            }
        },

        removeExtra(index) {
            this.product.extras.splice(index, 1)
        },

        async submit() {
            try {
                await this.$store.dispatch(this.product.id ? 'updateProduct' : 'createProduct', { product: this.product, newImage: this.newImage })
                this.$root.$bvToast.toast(`${this.product.name} - 商品${this.product.id ? '更新' : '新增'}成功`, { title: '成功', variant: 'success' })
                this.$router.push('/products')
            } catch(e) {
                console.log(e)
                this.$root.$bvToast.toast(`${this.product.name} - 商品${this.product.id ? '更新' : '新增'}失敗，請重試`, { title: '失敗', variant: 'danger' })
            }
        }
    }
}