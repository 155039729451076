import ListHeader from '@/components/ListHeader'
import ListTable from '@/components/ListTable'
import { mapState } from 'vuex'

export default {
    components: {
        ListHeader,
        ListTable,
    },

    data() {
        return {
            fields: [
                {
                    key: 'name',
                    label: '選項',
                },
                {
                    key: 'actions',
                    label: '功能',
                    thStyle: { width: '25%' },
                }
            ]
        }
    },

    computed: {
        ...mapState({
            items: state => state.items,
        })
    }
}