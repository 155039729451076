import { Item } from '@/models/index'

export default {
    data() {
        return {
            operation: this.$route.params.operation,
            item: null,
        }
    },

    async created() {
        switch (this.operation) {
            case 'create':
                this.item = Item()
                break;
            default:
                this.item = await this.$store.dispatch('getItem', this.$route.params.id)
                break;
        }
        if (!this.item) return this.$router.push('/items')
    },

    methods: {
        async submit() {
            const result = await this.$store.dispatch(this.item.id ? 'updateItem' : 'createItem', this.item)
            if (result) {
                this.$root.$bvToast.toast(`${this.item.name} - 配料選項${this.item.id ? '更新' : '新增'}成功`, { title: '成功', variant: 'success' })
                this.$router.push('/items')
            } else {
                this.$root.$bvToast.toast(`${this.item.name} - 配料選項${this.item.id ? '更新' : '新增'}失敗，請重試`, { title: '失敗', variant: 'danger' })
            }
        }
    }
}