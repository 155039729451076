import { mapState } from 'vuex'
import { Category } from '@/models/index'
import _ from 'lodash'

export default {
    data() {
        return {
            operation: this.$route.params.operation,
            category: null,
        }
    },

    async created() {
        switch (this.operation) {
            case 'create':
                this.category = Category()
                break;
            default:
                this.category = await this.$store.dispatch('getCategory', this.$route.params.id)
                break;
        }
        if (!this.category) return this.$router.push('/categories')
    },

    computed: {
        ...mapState({
            products: state => state.products
        })
    },

    methods: {
        addProduct(obj) {
            if (!_.find(this.category.products, { id: obj.id })) {
                this.category.products.push(_.cloneDeep(obj))
            }
        },

        removeProduct(index) {
            this.category.products.splice(index, 1)
        },

        async submit() {
            try {
                await this.$store.dispatch(this.category.id ? 'updateCategory' : 'createCategory', this.category)
                this.$root.$bvToast.toast(`${this.category.name} - 分類${this.category.id ? '更新' : '新增'}成功`, { title: '成功', variant: 'success' })
                this.$router.push('/categories')
            } catch(e) {
                console.log(e)
                this.$root.$bvToast.toast(`${this.category.name} - 分類${this.category.id ? '更新' : '新增'}失敗，請重試`, { title: '失敗', variant: 'danger' })
            }
        }
    }
}