import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/functions'
import 'firebase/storage'

firebase.initializeApp({
    apiKey: "AIzaSyDSaJjBCupxu3IVcDwUapTCBsDSRV9_oMo",
    authDomain: "nube-yiu-fung.firebaseapp.com",
    projectId: "nube-yiu-fung",
    storageBucket: "nube-yiu-fung.appspot.com",
    messagingSenderId: "96049187380",
    appId: "1:96049187380:web:3cbd888867f54054a11861",
    measurementId: "G-11S2NTPGJL"
})

// firebase.initializeApp({
//     apiKey: "AIzaSyAthlgKpf5yzDAg07cLXeiWIL61IyP6r_Y",
//     authDomain: "restore-test-4cb59.firebaseapp.com",
//     projectId: "restore-test-4cb59",
//     storageBucket: "restore-test-4cb59.appspot.com",
//     messagingSenderId: "809632125469",
//     appId: "1:809632125469:web:48605e7049d776782d6cf4",
//     measurementId: "G-5GRYS1L3DD"
// })

export const db = firebase.firestore()
export const auth = firebase.auth()
export const functions = firebase.functions()
export const storage = firebase.storage()
