import ListHeader from '@/components/ListHeader'
import ListTable from '@/components/ListTable'
import { mapState } from 'vuex'
import _ from 'lodash'

export default {
    components: {
        ListHeader,
        ListTable,
    },
    
    data() {
        return {
            fields: [
                {
                    key: 'image',
                    label: '圖片',
                    // stickyColumn: true,
                },
                {
                    key: 'name',
                    label: '商品',
                    sortable: true,
                    stickyColumn: true,
                },
                {
                    key: 'takeawayPrice',
                    label: '自取價錢',
                    formatter: value => {
                        return '$' + value.toFixed(1)
                    },
                    sortable: true
                },
                {
                    key: 'deliveryPrice',
                    label: '外送價錢',
                    formatter: value => {
                        return '$' + value.toFixed(1)
                    },
                    sortable: true
                },
                {
                    key: 'extras',
                    label: '配料',
                    formatter: value => {
                        let str = ''
                        _.forEach(value, (data, index) => {
                            if (index === value.length - 1) {
                                str += data.name
                            } else {
                                str += data.name + ', '
                            }
                        })
                        return str ? str : '-'
                    },
                    sortable: true
                },                
                {
                    key: 'actions',
                    label: '功能',
                    thStyle: { width: '25%' },
                }
            ]
        }
    },

    computed: {
        ...mapState({
            products: state => state.products,
        })
    }
}