import _ from 'lodash'

export default {
    state: {
        cart: {
			type: 'reservation',
            method: 'mobileToGo',
            address: '',
			products: [],
            remark: '',
			reservationTime: null,
		},
    },
    mutations: {
        updateCartMethod({ cart }, method) {
            cart.method = method
        },

        updateCartType({ cart }, type) {
            cart.type = type
        },

        updateCartReservationTime({ cart }, reservationTime) {
            cart.reservationTime = reservationTime
        },

        addToCart({ cart }, product) {
            cart.products.push(product)
        }
    },
    actions: {
        updateCartMethod({ commit }, method) {
            commit('updateCartMethod', method)
        },

        updateCartType({ commit }, type) {
            commit('updateCartType', type)
        },

        updateCartReservationTime({ commit }, reservationTime) {
            commit('updateCartReservationTime', reservationTime)
        },

        addToCart({ commit }, { product, extras, count }) {
            const newProduct = {
                id: product.id,
                name: product.name,
                items: _.flatten(_.map(extras, extra => extra.items)),
                takeawayPrice: product.takeawayPrice,
                deliveryPrice: product.deliveryPrice,
                count: count,
                remark: '',
            }
            commit('addToCart', newProduct)
        }
    }
}