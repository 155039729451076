import _ from 'lodash'

export default {
    state: {
        customers: [],
    },
    mutations: {},
    actions: {
        bindCustomers({ dispatch }) {
            return dispatch('bindCollection', 'customers')
        },

        bindCustomer({ dispatch }, id) {
            return dispatch('bindDocument',  { collection: 'customers', target: 'customer', id: id })
        },

        unbindCustomer({ dispatch }) {
            return dispatch('unbindState', 'customer')
        },

        async createCustomer({ dispatch }, data) {
            return await dispatch('createDocument', { collection: 'customers', data: data })
        },

        async getCustomer({ dispatch }, id) {
            return await dispatch('getDocument', { collection: 'customers', id: id })
        },

        async updateCustomer({ state, dispatch }, data) {
            _.forEach(state.products, async product => {
                const index = _.findIndex(product.customers, { id: data.id })
                if (index > -1) {
                    product.customers[index] = data
                    await dispatch('updateProduct', product)
                }
            })
            return await dispatch('updateDocument', { collection: 'customers', data: data })
        },
    },
}
