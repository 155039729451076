<template>
    <b-container fluid>
        <b-row>
            <b-col lg="6" class="my-1">
                <b-form-group
                label="搜尋"
                label-for="filter-input"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
                >
                <b-input-group size="sm">
                    <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="輸入內容"
                    ></b-form-input>

                    <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">取消</b-button>
                    </b-input-group-append>
                </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col sm="5" md="6" class="my-1">
                <b-form-group
                label="每頁顯示數量"
                label-for="per-page-select"
                label-cols-sm="6"
                label-cols-md="4"
                label-cols-lg="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
                >
                <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                ></b-form-select>
                </b-form-group>
            </b-col>
            <b-col sm="7" md="6" class="my-1">
                <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="sm"
                class="my-0"
                ></b-pagination>
            </b-col>
        </b-row>

        <b-table class="mt-3" :items="items" :fields="fields" :filter="filter" :sort-by.sync="fields.uniNum" striped hover sticky-header no-border-collapse :current-page="currentPage" :per-page='perPage' @filtered="onFiltered" style="max-height:70vh; overflow:scroll;">
            <template #cell(image)="row">
                <b-img-lazy v-if="row.item.image" thumbnail rounded :src="row.item.image" blank=false blankColor='#777' width=75 height=75 class='m1' style="min-width:75px; min-height: 75px;"></b-img-lazy>
            </template>
            <template #cell(actions)="row">
                <b-link class="mr-3" @click="$router.push(`/${value}/view/${row.item.id}`)">查看</b-link>
                <b-link class="mr-3" @click="$router.push(`/${value}/edit/${row.item.id}`)">修改</b-link>
            </template>
        </b-table>
    </b-container>
</template>

<script>
export default {
    props: ['value', 'items', 'fields'],
    data() {
        return {
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 20, 50, { value: 1000, text: "顯示所有" }],
            filter: null,
        }
    },
    mounted() {
        // Set the initial number of items
        this.totalRows = this.items.length
    },
    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        }
    }
}
</script>