export default {
    state: {
        shops: [],
    },
    mutations: {},
    actions: {
        bindShops({ dispatch }) {
            return dispatch('bindCollection', 'shops')
        },

        bindShop({ dispatch }, id) {
            return dispatch('bindDocument',  { collection: 'shops', target: 'shop', id: id })
        },

        unbindShop({ dispatch }) {
            return dispatch('unbindState', 'shop')
        },

        async createShop({ dispatch }, data) {
            return await dispatch('createDocument', { collection: 'shops', data: data })
        },

        async getShop({ dispatch }, id) {
            return await dispatch('getDocument', { collection: 'shops', id: id })
        },

        async updateShop({ dispatch }, data) {
            return await dispatch('updateDocument', { collection: 'shops', data: data })
        },
    },
}
