import _ from 'lodash'

export default {
    state: {
        categories: [],
    },
    mutations: {},
    actions: {
        bindCategories({ dispatch }) {
            return dispatch('bindCollection', 'categories')
        },

        async createCategory({ dispatch }, data) {
            return await dispatch('createDocument', { collection: 'categories', data: data })
        },

        async getCategory({ dispatch }, id) {
            return await dispatch('getDocument', { collection: 'categories', id: id })
        },

        async updateCategory({ state, dispatch }, data) {
            _.forEach(state.shops, async shop => {
                const index = _.findIndex(shop.categories, { id: data.id })
                if (index > -1) {
                    shop.categories[index] = data
                    await dispatch('updateShop', shop)
                }
            })
            return await dispatch('updateDocument', { collection: 'categories', data: data })
        },
    },
}
