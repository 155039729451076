import NavBar from '@/components/NavBar'

export default {
    components: {
        NavBar,
    },

    data() {
        return {
            links: [
                {
                    text: '商品分類', icon: 'tags', path: '/categories' 
                },
                {
                    text: '商品', icon: 'tags', path: '/products' 
                },
                {
                    text: '分店', icon: 'shop', path: '/shops'
                },
                {
                    text: '日結報表', icon: 'table', path: '/salesReport'
                },
                {
                    text: '產品報表', icon: 'table', path: '/productsReport'
                },
                {
                    text: '銷售訂單', icon: 'cart', path: '/orders' 
                }
            ],
        }
    }
}