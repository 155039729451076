<template>
    <b-navbar toggleable="xl">
        <b-navbar-brand to="/">
            <img src="@/assets/logo.svg"/>
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav>
                <div v-for="(link, index) in links" :key="index">
                    <b-nav-item v-if="link.path" :to="link.path">
                        <span><b-icon :icon="link.icon" style="vertical-align: -0.15em;" /> {{ link.text }}</span>
                    </b-nav-item>
                    <b-nav-item-dropdown v-if="link.children">
                        <template #button-content>
                            <span><b-icon :icon="link.icon" style="vertical-align: -0.15em;" /> {{ link.text }}</span>
                        </template>
                        <b-dropdown-item v-for="(childLink, index) in link.children" :key="index" :to="childLink.path">{{ childLink.text }}</b-dropdown-item>
                    </b-nav-item-dropdown>
                </div>
            </b-navbar-nav>
        </b-collapse>
    </b-navbar>
</template>

<script>
export default {
    props: ['links']
}
</script>
