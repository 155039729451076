import _ from 'lodash'

export default {
    state: {
        extras: [],
    },
    mutations: {},
    actions: {
        bindExtras({ dispatch }) {
            return dispatch('bindCollection', 'extras')
        },

        async createExtra({ dispatch }, data) {
            return await dispatch('createDocument', { collection: 'extras', data: data })
        },

        async getExtra({ dispatch }, id) {
            return await dispatch('getDocument', { collection: 'extras', id: id })
        },

        async updateExtra({ state, dispatch }, data) {
            _.forEach(state.products, async product => {
                const index = _.findIndex(product.extras, { id: data.id })
                if (index > -1) {
                    product.extras[index] = data
                    await dispatch('updateProduct', product)
                }
            })
            return await dispatch('updateDocument', { collection: 'extras', data: data })
        },
    },
}
