import _ from 'lodash'

export default {
    state: {
        items: [],
    },
    mutations: {},
    actions: {
        bindItems({ dispatch }) {
            return dispatch('bindCollection', 'items')
        },

        async createItem({ dispatch }, data) {
            return await dispatch('createDocument', { collection: 'items', data: data })
        },

        async getItem({ dispatch }, id) {
            return await dispatch('getDocument', { collection: 'items', id: id })
        },

        async updateItem({ state, dispatch }, data) {
            _.forEach(state.extras, async extra => {
                const index = _.findIndex(extra.items, { id: data.id })
                if (index > -1) {
                    extra.items[index] = data
                    await dispatch('updateExtra', extra)
                }
            })
            return await dispatch('updateDocument', { collection: 'items', data: data })
        },
    },
}
