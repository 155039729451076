// NubeTech Util v1.0 (21032201)
import _ from 'lodash'
import moment from 'moment'

export function renderPrice(method, obj) {
    switch (method) {
        case 'toGo':
            return obj.takeawayPrice
        case 'delivery':
            return obj.deliveryPrice
        case 'mobileToGo':
            return obj.takeawayPrice
        case 'mobileDelivery':
            return obj.deliveryPrice
    }
}

export function renderProductSubTotal(method, product) {
    let productPrice = 0
    let itemsPrice = 0
    switch (method) {
        case 'toGo':
            productPrice = product.takeawayPrice
            itemsPrice = _.chain(product.items).map('takeawayPrice').sum().value()
            break
        case 'delivery':
            productPrice = product.deliveryPrice
            itemsPrice = _.chain(product.items).map('deliveryPrice').sum().value()
            break
        case 'mobileToGo':
            productPrice = product.takeawayPrice
            itemsPrice = _.chain(product.items).map('takeawayPrice').sum().value()
            break
        case 'mobileDelivery':
            productPrice = product.deliveryPrice
            itemsPrice = _.chain(product.items).map('deliveryPrice').sum().value()
            break
    }
    return (productPrice + itemsPrice) * product.count
}

export function renderGrandTotal(cart) {
	return _.cloneDeep(cart.products).map(product => {
		switch (cart.method) {
			case 'toGo':
				return (product.takeawayPrice + _.chain(product.items).map('takeawayPrice').sum().value()) * product.count
			case 'delivery':
				return (product.deliveryPrice + _.chain(product.items).map('deliveryPrice').sum().value()) * product.count
			case 'mobileToGo':
				return (product.takeawayPrice + _.chain(product.items).map('takeawayPrice').sum().value()) * product.count
			case 'mobileDelivery':
				return (product.deliveryPrice + _.chain(product.items).map('deliveryPrice').sum().value()) * product.count
		}
	}).reduce((grandTotal, subTotal) => {
		return grandTotal + subTotal
	}, 0)
}

export function renderOrderMethod(method) {
	switch (method) {
		case 'toGo':
			return '自取'
		case 'delivery':
			return '外送'
		case 'mobileToGo':
			return '自取(網上)'
		case 'mobileDelivery':
			return '外送(網上)'
		default:
			return ''
	}
}

export function renderTimeOptions(date, shop) {
    // 確認是否為今日 || 無設定時間
    let isToday = (date === moment().format('MM/DD') || date === null)
    // 今日：由現在時間計算時間；非今日：由當日0:00開始計算
	let now = isToday ? moment().format('HH:mm') : moment(date).startOf('d').format('HH:mm')
	// 取得星期數
	let todayWeekDay = isToday ? moment().isoWeekday() - 1 : moment(date, 'MM/DD').isoWeekday() - 1
    // 商店的營業時間設定
	let time = shop.openingHours[todayWeekDay]
	// 商店的最短取餐時間設定
	let minWaitTime = shop.isBusy ? shop.busyTime : shop.readyTime
    let options = []
	// 透過商店的每個營業區間，產生可取餐的時間選項
	if (!time) {
		return []
	} else if (time.length === 0) {
		return []
	} else if (date && moment(date, 'MM/DD').isBefore(moment().startOf('d'))) {
		return []
	}
    _.forEach(time, (t) => {
		let nowHour = Number(now.split(':')[0])
		let nowMinutes = Number(now.split(':')[1]) + minWaitTime
		let minutesArr = [0, 15, 30, 45]
		// 透過現在時間，取得下一個最接近的取餐分鐘數
		if (nowMinutes >= 45) {
			let addHour = Math.floor(nowMinutes / 60)
			let addMin = Math.ceil((nowMinutes - addHour * 60) / 15) % 4
			nowHour += addHour
			if (addMin === 0) {
				nowHour += 1
				nowMinutes = 0
			} else {
				nowMinutes = minutesArr[addMin]
			}
		} else {
			nowMinutes = minutesArr[(Math.floor(nowMinutes / 15) + 1) % 4]
		}
		let startHour = Number(t.start.split(':')[0])
		let startMinutes = Number(t.start.split(':')[1])
		let endTime = moment({
			h: t.end.split(':')[0],
			m: t.end.split(':')[1]
		}).format('HH:mm')
		let endHour = Number(endTime.split(':')[0])
		let endMinutes = Number(endTime.split(':')[1])
		// 取得計算選項的開始時間，如果現在時間 > 開店時間，則取用現在時間
		let startTime = ((nowHour > startHour) || (nowHour === startHour && nowMinutes > startMinutes)) ? {
			h: nowHour,
			m: nowMinutes
		} : {
			h: startHour,
			m: startMinutes
		}
		// 取得產生選項的次數（15 min/次）
		let timeGap = ((endHour - startTime.h) * minutesArr.length) + (minutesArr.indexOf(endMinutes) - minutesArr.indexOf(startTime.m)) + 1
		for (let i = 0; i < timeGap; i++) {
			let obj = {
				label: moment(startTime).add(15 * i, 'm').format('HH:mm'),
				value: moment(startTime).add(15 * i, 'm').format('HH:mm')
			}
			options.push(obj)
		}
	})
	return options
}